<!--  -->
<template>
  <div>
    <!-- <div class="mainCont"></div> -->
    <!-- <div class="def-container tabBtn">
      <div class="bannerTxt">
        <span class="chTxt">免申即享</span><br />
        <span class="enTxt">WITHOUT APPLY</span>
      </div>
    </div> -->
    <div class="def-container totalInfo">
      <div>
        共<span class="num">
          {{ totleMap.qb }}
        </span>
        条政策项目
        <span class="other">
          （国家级{{ totleMap.gj }}条、省级{{ totleMap.sj }}条、市级{{
            totleMap.dsj
          }}条）
        </span>
      </div>
      <!-- <div @click="policyVisible = true" class="btn">完善信息</div> -->
    </div>
    <div class="def-container">
      <div>
        <div
          class="qw-list-empty def-container"
          v-if="policyInfo.deptPolicyData.length == 0"
        >
          <div class="qw-list-empty__inner">
            <div>
              <i class="el-icon-folder-delete"></i>
            </div>
            <div><span> 暂无数据 </span></div>
          </div>
        </div>
        <div style="position: relative">
          <!-- v-else -->
          <div
            class="box-card"
            :key="index"
            v-for="(item, index) in policyInfo.deptPolicyData"
          >
            <el-row class="titleRow">
              <img
                src="@/assets/images/favorites_off.png"
                width="24"
                height="24"
                alt=""
              />
              <span style="color: #333; font-weight: 700; font-size: 18px">
                【{{ item.areaid }}】
              </span>
              <span
                class="limitWidthSpan"
                :title="item.sxmc"
                style="color: #333; font-weight: 700; font-size: 18px;"
                @click="tonewpage(item)"
              >
                {{ item.sxmc }}（免申即享）
              </span>
            </el-row>
            <el-row class="box-line">
              <el-col :span="2" class="box-line-title">发文单位:</el-col>
              <el-col :span="20" :title="item.fbjg">
                {{ handleFbjg(item.fbjg) }}
              </el-col>
              <!-- <el-col :span="2" class="box-line-title">申报时间:</el-col>
              <el-col :span="15">
                {{ moment(item.starttime).format("YYYY-MM-DD") }}至{{
                  moment(item.endtime).format("YYYY-MM-DD")
                }}
              </el-col> -->
            </el-row>
            <!-- <el-row class="box-line">
              <el-col :span="2" class="box-line-title">资金限额:</el-col>
              <el-col :span="5" style="color: #ff3131"
                >{{ item.zzxe || 0 }}万元</el-col>
            </el-row> -->
            <el-row class="box-line" v-if="item.tkhText">
              <el-col :span="2" class="box-line-title">资金限额:</el-col>
              <el-col :span="12">{{ item.tkhText || "" }}</el-col>
            </el-row>
            <div class="wssb">
              <div
                class="btn"
                :disabled="!item.ksbflag"
                @click="goDeclare(item)"
              >
                办事指南
              </div>
              <!-- @click="openfile(item)" -->
              <div
                class="btn"
                :disabled="!item.ksbflag"
                @click="toPolicyView(item)"
              >
                政策原文
              </div>
              <template v-if="handleTime(item)">
                <div
                  v-show="item.isApply && item.isApply === '1'"
                  class="btn"
                  @click="toApply(item)"
                >
                  完善信息
                </div>
              </template>
              <template v-else>
                <el-button disabled style="width: 120px;height: 40px;">申报结束</el-button>
              </template>
            </div>
          </div>
          <div class="content-pagination">
            <el-pagination
              :total="pageInfoDept.total"
              :current-page.sync="pageInfoDept.num"
              :page-size="pageInfoDept.size"
              background
              layout="total,prev,pager,next,jumper"
              @current-change="changePage($event, 'dept')"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="完善信息"
      :visible.sync="policyVisible"
      :modal-append-to-body="false"
    >
      <el-form label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="企业名称">
              <el-input v-model="userInfo.enterpriseInfo.enterprise" disabled>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="统一信用代码">
              <el-input v-model="userInfo.tyshxydm" disabled> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人">
              <el-input v-model="userList.name"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话">
              <el-input v-model="userList.phone"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <p
        style="
          color: #333;
          line-height: 40px;
          font-size: 16px;
          font-weight: bold;
        "
      >
        银行账户
      </p>
      <el-form
        ref="elForm"
        :model="formData"
        label-width="120px"
        :rules="rules"
      >
        <el-col :span="12">
          <el-form-item label="账户名称" prop="bank">
            <el-input
              v-model="formData.bank"
              placeholder="请输入账户名称"
              clearable
              :style="{ width: '100%' }"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="所属银行" prop="ssyh">
            <el-select
              v-model="formData.ssyh"
              placeholder="请选择所属银行"
              clearable
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="(item, index) in ssyhOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="开户行" prop="zhmc">
            <el-input
              v-model="formData.zhmc"
              placeholder="请输入开户行"
              clearable
              :style="{ width: '100%' }"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="账号" prop="account">
            <el-input
              v-model="formData.account"
              placeholder="请输入账号"
              clearable
              :style="{ width: '100%' }"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12"
          ><div style="width: 100%; height: 40px"></div>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="policyVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">保 存</el-button>
      </span>
    </el-dialog>
    <el-dialog title="" :visible.sync="outerVisible">
      <el-button @click="upload.open = true" type="primary">上传附件</el-button>
      <p class="historyFileList">历史上传附件列表</p>
      <el-table :data="flielists" style="width: 100%">
        <el-table-column label="文件名称" prop="filename" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleDownload(scope.row)"
              style="margin-right: 20px"
              >下载</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 15px; text-align: center">
        <el-pagination
          :total="pageInfoFile.total"
          :current-page.sync="pageInfoFile.num"
          background
          layout="total,prev,pager,next,jumper"
          @current-change="changeFilePage($event)"
        ></el-pagination>
      </div>
      <el-dialog
        title="附件上传"
        :visible.sync="upload.open"
        width="450px"
        append-to-body
      >
        <div style="margin: 20px">
          <el-upload
            ref="upload"
            drag
            class="upload-demo"
            :action="action"
            :on-success="handleFileSuccess"
            :before-upload="beforeAvatarUpload"
            multiple
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitFileForm">确 定</el-button>
          <el-button @click="upload.open = false">取 消</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      moment,
      policyVisible: false,
      outerVisible: false,
      // 政策信息
      policyInfo: {
        data: [],
        deptPolicyData: [],
      },
      pageInfoDept: {
        total: 0,
        num: 1,
        size: 3,
      },
      formData: {
        bank: "", // 账户名称
        ssyh: "", // 所属银行
        zhmc: "", // 支行名称
        account: "", // 银行卡号
      },
      totleMap: {},
      rules: {
        bank: [
          {
            required: true,
            message: "请输入账户名称",
            trigger: "blur",
          },
        ],
        ssyh: [
          {
            required: true,
            message: "请选择所属银行",
            trigger: "change",
          },
        ],
        zhmc: [
          {
            required: true,
            message: "请输入支行名称",
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
          // {
          //   pattern: /^([1-9]{1})(\d{10}|\d{11,19})$/,
          //   message: "账号格式不正确",
          // },
          // {pattern:/^([1-9]{1})(\d{14}|\d{18})$/,message: '账号格式不正确',}
        ],
        field105: [
          {
            required: true,
            message: "请选择账户类型",
            trigger: "change",
          },
        ],
      },
      ssyhOptions: [],
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 是否禁用上传
        // isUploading: false,
        // 上传的地址
      },
      action:
        process.env.VUE_APP_BASE_API + "/dev-api/fileManageApi/common/upload",
      baseUrl: process.env.VUE_APP_BASE_API,
      fliesubmitData: {},
      fileIds: [],
      flielists: [],
      pageInfoFile: {
        total: 0,
        num: 1,
        pagesie: 5,
      },
      userList: {},
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      accountId: "getAccountId",
      storeguideId: "getCurrentGuideId",
    }),
  },
  created() {
    this.formData.webEid = this.userInfo.tyshxydm;
    this.getDataList();
    this.getuserList();
  },
  mounted() {
    this.getAccType();
    this.getList();
  },
  methods: {
     // 处理申报时间
    handleTime(item) {
      const NOW = new Date();
      // 未开始/剩余天数/已过期
      let startTime = new Date(item.starttime||item.startTime);
      if (NOW < startTime) return false;

      let endTime = new Date(item.endtime);
      if (endTime < NOW) return false;
      return true;
    },
    // 一键申报
    toApply(item) {
      if (
        item.sxid == "e3d1fa8f6e5c4620ab28ba63d1b272fb" ||
        item.sxid == "7d7cd03229954c269756ef4e4df06a5c"
      ) {
        // 测试用的sxid
        // if(item.sxid=='31a2d9fe9d674573905821948c55dfc0'){
        // 只有事项id为这两个的时候，页面显示的字段跟其他不一样
        sessionStorage.setItem("isSpecialProject", "true");
      } else {
        sessionStorage.setItem("isSpecialProject", "false");
      }
      let templateInfo = {
        templateFlag: item.templateFlag,
        templateFileId: item.templateFileId,
        templateFileName: item.templateFileName,
      };
      templateInfo = JSON.stringify(templateInfo);
      this.$store.commit("updateCurrentGuideId", item.sxid);
      this.$router.push({
        name: "policyDeclaration",
        query: {
          policyId: this.storeguideId,
          templateInfo: templateInfo,
        },
      });
    },
    // 历史上传附件分页
    changeFilePage() {},
    // 附件上传
    handleDownload(row) {
      let url = this.baseUrl + `/common/fileView/${row.fileid}/download`;
      window.location.href = url;
    },
    handleDelete(row) {
      let url = "/dev-api/system/file/deleteyFileByFileId";
      let params = Object.assign({}, row);
      params["fileId"] = row.fileid;
      delete params.fileid;
      params["tyshxydm"] = this.userInfo.tyshxydm;
      this.$httpApi.post(url, params).then((res) => {
        if (res.code == 200) {
          this.$message.success("删除成功！");
          this.getFileList();
        }
      });
    },
    handleFbjg(val) {
      if (val) {
        if (val.length > 36) {
          return val.substr(0, 36) + "...";
        } else {
          return val;
        }
      }
    },
    goDeclare(item) {
      this.$store.commit("updateCurrentGuideId", item.sxid);
      this.$router.push({
        path: "/guideSuqiu",
        query: {
          name: item.sxmc,
          itemType: "2",
          isAdded: item.isAdded,
        },
      });
    },
    openPolicy() {
      this.policyVisible = true;
    },
    /**
     * 获取银行列表
     *
     * @param { 页面，页面大小，webEid }
     * @returns { Array }	账户列表
     */
    getList() {
      let url = "/dev-api/enterprise/bank/list";
      let params = {
        pageSize: 100, // 页码大小
        pageNum: 0, // 页码页码
        webEid: this.formData.webEid,
      };
      this.$httpApi
        .get(url, params)
        .then((res) => {
          console.log("获取银行列表>>>", res);
          if (res.code === 200 && res.rows.length > 0) {
            let resList = JSON.parse(JSON.stringify(res.rows));
            const _that = this;
            _that.allFormData = resList; // 存下所有
            _that.formData = resList[0];
          }
        })
        .catch((err) => {});
    },
    /**
     * 所属银行
     */
    getAccType() {
      this.getClassWrap("sys_acc_bank", "获取所属银行").then((res) => {
        // this.field106Options = res
        this.ssyhOptions = res;
      });
    },
    /**
     * 处理数据通用
     */
    async getClassWrap(el, title) {
      let endList = [];
      await this.getDictionaryInfo(el).then((res) => {
        if (res === 0) {
          console.log("字典暂无数据>>>");
        } else {
          // 处理数据
          // console.log(`${title || ''}处理前>>>`, res)
          const resList = JSON.parse(JSON.stringify(res.data));
          let target = [];
          for (const item of resList) {
            target.push({
              id: item.sort, // 0
              value: item.key, // "A"
              label: item.label, // "农、林..."
            });
          }
          // console.log(`获取${title || ''}处理后的数据>>>`, target)
          endList = target;
        }
      });
      return endList;
    },
    /**
     * 通用：根据字典类型获取字典信息
     */
    async getDictionaryInfo(el) {
      let response;
      await this.$httpApi
        .get("/dev-api/system/dict/getDictListByType", { dictType: el })
        .then((res) => {
          // console.log("根据字典类型获取字典信息>>>", res)
          console.log("--------------------------------------------");
          if (res.code == 200 && res.data.length !== 0) {
            response = res;
          } else {
            response = 0;
          }
        })
        .catch((err) => {});
      return response;
    },
    /**
     * 按钮
     */
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) return;
        // TODO 提交表单
        this.addUp();
      });
    },
    /**
     * 新建 或 修改
     *
     */
    addUp() {
      let that = this;
      let url = this.formData.id
        ? "/dev-api/enterprise/bank/update"
        : "/dev-api/enterprise/bank/install";
      let params = this.formData;
      console.log("提交参数>>>", params);
      this.$httpApi
        .postByBody(url, params)
        .then((res) => {
          console.log("返回>>>", res);
          if (res.code === 200) {
            this.$message({
              message: "提交银行账户成功！",
              type: "success",
            });
          } else if (res.code === 500) {
            this.$message({
              message: "参数错误！",
              type: "error",
            });
          }
          this.policyVisible = false;
        })
        .then((r) => {
          let contacturl = "/dev-api-jh/enterprise/contact/update";
          that.userList.createTime = new Date(that.userList.createTime);
          that.$httpApi
            .postByBody(contacturl, that.userList)
            .then((res) => {
              console.log("返回>>>", res);
              if (res.code === 200) {
                this.$message({
                  message: "提交联系人信息成功！",
                  type: "success",
                });
              } else if (res.code === 500) {
                this.$message({
                  message: "参数错误！",
                  type: "error",
                });
              }
              this.getuserList();
              this.getList();
            })
            .catch((err) => {});
        });
    },
    getDataList() {
      let params = {
        pageSize: this.pageInfoDept.size,
        pageNum: this.pageInfoDept.num,
        tyshxydm: this.userInfo.tyshxydm,
      };
      let url = "/dev-api/system/apply/querySqAvoidApplyList";
      this.$httpApi.post(url, params).then((res) => {
        if (res.code == 200) {
          this.pageInfoDept.total = res.data.pageInfo.total;
          debugger
          let numObj = {
            dsj: res.data.dsj,
            gj: res.data.gj,
            sj: res.data.sj,
            qb: res.data.pageInfo.total,
            zzxe: res.data.zzxe,
          };
          this.totleMap = numObj;
          let temp = [];
          res.data.pageInfo.list.forEach((item) => {
            var aData = new Date();
            var year = aData.getFullYear(); //年
            var month = aData.getMonth(); //月
            if (month < 9) {
              month = "0" + (month + 1);
            }
            var day = aData.getDate(); //日
            let ndate = year + "-" + month + "-" + day;
            let startTime = item.starttime.substr(0, 10);
            let endTime = item.endtime.substr(0, 10); //年
            if (startTime < ndate && ndate < endTime) {
              item["ksbflag"] = true;
            } else {
              item["ksbflag"] = false;
            }
            temp.push(item);
          });
          this.policyInfo.deptPolicyData = temp;
        }
      });
    },
    changePage(page, param) {
      this.pageInfoDept.num = page;
      this.getDataList();
    },
    tonewpage(item) {
      // this.$router.push({
      //   name: "policyMatchDetails",
      //   params: {
      //     listData: item,
      //   },
      // });
    },
    openfile(item) {
      this.outerVisible = true;
      this.fliesubmitData = item;
      this.getFileList();
    },
    // 政策原文
    toPolicyView(row) {
      let that = this;
      let { href } = that.$router.resolve({
        path: "/notice/detail/" + row.policyid,
        query: {
          // linkType:true,
          code: row.code,
          sxid: row.sxid,
        },
      });
      sessionStorage.setItem("linkType", true);
      window.open(href, "_self");
    },
    submitFileForm() {
      if (this.fileIds.length == 0) {
        this.$message.warning("请检查是否有上传文件，如有上传文件请稍后重试");
        return;
      }
      let params = {
        projectId: this.fliesubmitData.sxid,
        projectName: this.fliesubmitData.sxmc,
        policyId: this.fliesubmitData.policyid,
        policyName: this.fliesubmitData.policyname,
        tyshxydm: this.userInfo.tyshxydm,
        enterpriseName: this.userInfo.enterpriseInfo.enterprise,
        fileId: this.fileIds.join(","),
      };
      let url = "/dev-api/system/file/add";
      this.$httpApi.post(url, params).then((r) => {
        if (r.code == 200) {
          this.$message.success("附件上传并与政策绑定成功");
          this.fileIds = [];
          this.upload.open = false;
          this.$refs.upload.clearFiles();
          this.getFileList();
        } else {
          this.$message.error(r.msg);
        }
      });
    },
    handleFileSuccess(file, fileList) {
      if (fileList.response.code == 200) {
        this.fileIds.push(fileList.response.fileId);
      } else {
        this.$message.error(fileList.response.msg);
      }
    },
    getFileList() {
      let params = {
        pageSize: this.pageInfoFile.pagesie,
        pageNum: this.pageInfoFile.num,
        projectId: this.fliesubmitData.sxid,
        policyId: this.fliesubmitData.policyid,
        tyshxydm: this.userInfo.tyshxydm,
      };
      let url = "/dev-api/system/file/queryFileByInfo";
      this.$httpApi.post(url, params).then((r) => {
        if (r.code == 200) {
          this.pageInfoFile.total = r.data.total;
          this.flielists = r.data.list;
        }
      });
    },
    getuserList() {
      let url = "/dev-api-jh/enterprise/contact/webId/" + this.formData.webEid;
      this.$httpApi
        .get(url)
        .then((res) => {
          console.log("获取联系人列表>>>", res);
          if (res.code === 200) {
            let resList = JSON.parse(JSON.stringify(res.data));
            const _that = this;
            _that.userList = resList;
          }
        })
        .catch((err) => {});
    },
    beforeAvatarUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["pdf", "doc", "docx", "xls", "xlsx", "txt"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error(
          "上传文件只能是 pdf、doc、docx、xls、xlsx、txt格式"
        );
        return false;
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.limitWidthSpan {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.titleRow {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.content-pagination {
  height: 50px;
  text-align: center;
  margin: 20px auto;
}
.mainCont {
  width: 100%;
  height: 200px;
  background: url("~@/assets/deyangImg/jztuisong.png") no-repeat center / cover;
  display: flex;
  align-items: center;
}
.tabBtn {
  display: flex;
  position: relative;
  .bannerTxt {
    position: absolute;
    top: -163px;
    left: 25px;
    color: #fff !important;
    background: transparent;
    .chTxt {
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 5px;
    }
    .enTxt {
      font-size: 22px;
      font-weight: 500;
      word-spacing: 5px;
      letter-spacing: 2px;
    }
  }
}
.qw-list-empty {
  width: 100%;
  min-height: 200px;
  height: 20vh;
  position: relative;
  background: #fff;
  .qw-list-empty__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #989899;
    span {
      font-size: 18px;
      line-height: 24px;
    }
    i {
      font-size: 36px;
      line-height: 80px;
    }
    .toBaseInfoEdit {
      cursor: pointer;
      color: #23caf2;
      text-decoration: underline;
    }
  }
}
.box-card {
  margin: 20px auto;
  margin-left: -20px;
  min-height: 120px;
  position: relative;
  background: #f9f9f9;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 15px 8px;
  padding-left: 20px;
  .box-line {
    margin: 12px 0;
    display: flex;
    font-size: 18px;
    color: #666;
    line-height: 20px;
    .box-line-title {
      font-weight: 600;
    }
  }
}
.wssb {
  position: absolute;
  top: 60px;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  z-index: 2;
  .btn {
    cursor: pointer;
    width: 120px;
    height: 40px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #0e97ff;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #0e97ff;
    margin-right: 20px;
    transition: background-color 0.3s, color 0.3s;
    &:hover {
      color: #fff;
      background: #0e97ff;
    }
  }
}
.btn {
  cursor: pointer;
  width: 120px;
  height: 40px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #0e97ff;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #0e97ff;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    color: #fff;
    background: #0e97ff;
  }
}
.totalInfo {
  display: flex;
  justify-content: space-between;
  padding: 0;
  padding-right: 1rem;
  font-size: 22px;
  color: #666666;
  margin-bottom: 12px;
  .num {
    color: #fd860f;
    margin: 0 5px;
  }
  .other {
    font-size: 16px;
    color: #bbbbbb;
  }
}
.historyFileList {
  width: 100%;
  text-align: center;
  margin: 15px auto;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
</style>
